import React, { useState } from 'react';
import styles from './push.module.css';

function Push() {
  const [message, setMessage] = useState('');
  const [datetime, setDatetime] = useState('');
  const [store, setStore] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [charCount, setCharCount] = useState(105);
  const [isSuccess, setIsSuccess] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!store.trim()) {
      newErrors.store = 'Store selection is required.';
    }

    setError(newErrors.datetime || newErrors.store || '');
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://pushNotification-me6qgdhunq-uc.a.run.app', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pushText: message, pushDateTime: datetime, store }),
      });

      setIsLoading(false);

      if (response.ok) {
        setMessage('');
        setDatetime('');
        setStore(''); 
        setCharCount(105);
        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting message:", error);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setCharCount(105 - e.target.value.length);
  };

  const handleDatetimeChange = (e) => {
    setDatetime(e.target.value);
  };

  const handleStoreChange = (e) => {
    setStore(e.target.value);
  };

  return (
    <div className={styles.flex}>
      {!isSuccess ? (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputContainer}>
            <select
              name="store"
              value={store}
              onChange={handleStoreChange}
              className={styles.inputBox}
              required
            >
              <option value="">Store Selector</option>
              <option value="All">All</option>
              <option value="Buck Town">Buck Town</option>
              <option value="Broadview">Broadview</option>
              <option value="StLouis">St. Louis</option>
              <option value="Hoboken">Hoboken</option>
            </select>

            <div className={styles.textAreaContainer}>
              <textarea
                name="message"
                value={message}
                onChange={handleMessageChange}
                className={styles.inputBox}
                placeholder="Walk into a store now and get 40% of your first order using the secret code: HELLO2025..."
                rows="5"
                maxLength="105"
              ></textarea>
              <div className={styles.charCount}>{charCount} characters remaining</div>
            </div>

            <div className={styles.comingSoonText}>Coming soon: Schedule Push Notifications</div>
            <input
              type="datetime-local"
              name="datetime"
              value={datetime}
              onChange={handleDatetimeChange}
              className={styles.inputBox}
              disabled
            />

            {error && <div className={styles.error}>{error}</div>}

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button} disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className={styles.registrationComplete}>
          <div className={styles.checkmark}>✓</div>
          <h2>Push Notification Sent!</h2>
        </div>
      )}
    </div>
  );
}

export default Push;
