import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Form from './Form';
import Push from './Push';

// Create a wrapper component to use the hook
function FormWrapper() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referral = searchParams.get('referral'); // Example query param 'referral'

  // Pass the referral query param to the Form component if it exists
  return <Form referral={referral} />;
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route exact path="/" element={<FormWrapper />} />
            <Route path="/push" element={<Push/>} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;