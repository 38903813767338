import React, { useState } from 'react';
import styles from './form.module.css';

function Form({ referral }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    referral: referral || '',
    source: '',
    favSpot: '',
    isTwentyOneOrOlder: false,
  });
  const [errors, setErrors] = useState({});
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber.replace(/[^\d]/g, ''))) {
      newErrors.phoneNumber = 'Phone number is invalid';
    }
    if (!formData.isTwentyOneOrOlder) {
      newErrors.isTwentyOneOrOlder = 'Must be selected if you are over 21.';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    try {
      const response = await fetch('https://villagebackend-me6qgdhunq-uc.a.run.app', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      setIsLoading(false);

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.linkToPassPage;
        setRegistrationComplete(true);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  return (
    <div className={styles.flex}>
      {!registrationComplete ? (
        <>
          {isLoading ? (
            <div className={styles.logoSpinner}>
              <img src={`${process.env.PUBLIC_URL}/v-logo-only.png`} alt="Loading..." />
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={styles.form}>
              <img src={`${process.env.PUBLIC_URL}/village-dispensary-logo.png`} alt="Village Dispensary Logo" className={styles.logo} />
              <div className={styles.inputContainer}>
                <h2> Activate your Membership </h2>
                <input type="text" name="name" value={formData.name} onChange={handleChange} className={styles.inputBox} placeholder="What should we call you?" />
                {errors.name && <div className={styles.error}>{errors.name}</div>}
                <input type="email" name="email" value={formData.email} onChange={handleChange} className={styles.inputBox} placeholder="youremail@rollspliffs.com"/>
                {errors.email && <div className={styles.error}>{errors.email}</div>}
                <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className={styles.inputBox} placeholder="Your Phone Number..." />
                {errors.phoneNumber && <div className={styles.error}>{errors.phoneNumber}</div>}
                <input type="text" name="source" value={formData.source} onChange={handleChange} className={styles.inputBox} placeholder="Did a business or a member recommend you?" />
                <select name="favSpot" value={formData.favSpot} onChange={handleChange} className={`${styles.inputBox} ${styles.selectBox}`} required>
                  <option value="">which location is your plug?</option>
                  <option value="Buck Town">Buck Town</option>
                  <option value="Broadview">Broadview</option>
                  <option value="St. Louis">St. Louis</option>
                  <option value="Hoboken">Hoboken</option>
              </select>
                {errors.favSpot && <div className={styles.error}>{errors.favSpot}</div>}
                <label className={styles.checkboxLabel}>
                  21+ years or older?
                  <input type="checkbox" name="isTwentyOneOrOlder" checked={formData.isTwentyOneOrOlder} onChange={handleChange} />
                  <span className={styles.checkmark}></span>
                </label>
                {errors.isTwentyOneOrOlder && <div className={styles.error}>{errors.isTwentyOneOrOlder}</div>}
                <button type="submit" className={styles.button} disabled={isLoading}>Register</button>
              </div>
            </form>
          )}
        </>
      ) : (
        <div className={styles.registrationComplete}>
          <div className={styles.checkmark}>✓</div>
          <h2>Thanks for Registering</h2>
        </div>
      )}
    </div>
  );
}

export default Form;